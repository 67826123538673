import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { IRootState } from '.';
import { Environment } from '../api';
import * as api from '../api';

export interface IEnvironmentState {
  items: Environment[] | null;
  item: Environment | null;
}

const state: IEnvironmentState = {
  items: null,
  item: null,
};

const getters: GetterTree<IEnvironmentState, IRootState> = {
  items: (state: IEnvironmentState) => state.items,
  item: (state: IEnvironmentState) => state.item,
};

const actions: ActionTree<IEnvironmentState, IRootState> = {
  loadItems: async (context: ActionContext<IEnvironmentState, IRootState>) => {
    const { environments } = await api.listEnvironments({ project: context.rootState.project.currentItem?._id });
    context.commit('setItems', environments);
  },
  loadItem: async (context: ActionContext<IEnvironmentState, IRootState>, id: string) => {
    const item = await api.getEnvironment(id);
    context.commit('setItem', item);
  },
  loadItemForDuplication: async (context: ActionContext<IEnvironmentState, IRootState>, id: string) => {
    const item = await api.getEnvironment(id);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { _id, description, ...rest } = item;
    const duplicatedItem = {
      description: `${description} (copy)`,
      ...rest,
    };
    context.commit('setItem', duplicatedItem);
  },
  createNewItem: (context: ActionContext<IEnvironmentState, IRootState>) => {
    const project = context.rootState.project.currentItem?._id;
    if (typeof project === 'undefined') {
      throw new Error('No project set');
    }
    const item: api.CreateKnimeEnvironment = {
      type: 'knime',
      description: '',
      knimeVersion: '5.4.3',
      vmargs: '',
      vmargsEnabled: false,
      project,
      // set these optional fields to emtpy array, otherwise they are not
      // reactive in the ExtensionSearch component and thus can not be set.
      ius: [],
      repositories: [],
    };
    context.commit('setItem', item);
  },
  saveItem: async (context: ActionContext<IEnvironmentState, IRootState>) => {
    if (!context.state.item) {
      throw new Error('No item in context');
    }
    let item;
    if (context.state.item._id) {
      item = await api.updateEnvironment(context.state.item._id, context.state.item);
    } else {
      item = await api.createEnvironment(context.state.item);
    }
    context.commit('setItem', item);
  },
  deleteItem: async (context: ActionContext<IEnvironmentState, IRootState>, id: string) => {
    await api.deleteEnvironment(id);
    context.dispatch('loadItems');
  },
};

const mutations: MutationTree<IEnvironmentState> = {
  setItems(state: IEnvironmentState, items: Environment[] | null) {
    state.items = items;
  },
  setItem(state: IEnvironmentState, item: Environment | null) {
    state.item = item;
  },
};

const environment: Module<IEnvironmentState, IRootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default environment;
