



























































































































































































































































import EnvironmentIcon from '@/components/EnvironmentIcon.vue';
import Graph from '@/components/Graph.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAnglesDown,
  faBan,
  faClock,
  faCopy,
  faDownload,
  faDownLong,
  faExclamationTriangle,
  faLayerGroup,
  faRocket,
  faSearch,
  faStopwatch,
  faSync,
  faTableList,
  faTags,
  faUpLong,
  faUsers,
  faX,
} from '@fortawesome/free-solid-svg-icons';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as api from '../api';
import { Project, Run, RunStats } from '../api';
import DateTime from '../components/DateTime.vue';
import RunStatus from '../components/RunStatus.vue';
import { IToast } from '../store/toast.module';
import { downloadUrl, formatDuration, formatDurationShort, toSeconds } from '../utils';

library.add(
  faExclamationTriangle,
  faBan,
  faSync,
  faAnglesDown,
  faUsers,
  faLayerGroup,
  faClock,
  faTags,
  faStopwatch,
  faRocket,
  faSearch,
  faTableList,
  faCopy,
  faX,
  faDownload,
  faExclamationTriangle,
  faUpLong,
  faDownLong
);

const run = namespace('run');
const toast = namespace('toast');
const project = namespace('project');

@Component({
  components: { RunStatus, DateTime, EnvironmentIcon, Graph },
})
export default class RunView extends Vue {
  /** Only show the UI once the data has loaded;
   * needed to wrap this into an object, by just
   * using a plain flag it wouldn’t work -- binding
   * issue? */
  state: { loaded: boolean } = { loaded: false };

  private pollInterval: number | undefined;
  private tickInterval: number | undefined;
  private now = new Date();
  private inSearchMode = false;
  private q = '';

  @run.Getter
  item!: Run;

  @run.Getter
  private log!: string[];

  @run.Getter
  private stats!: RunStats;

  @run.Action
  private loadItem!: (id: string) => Promise<void>;

  @run.Action
  private updateItem!: (id: string) => Promise<void>;

  @run.Action
  private loadLog!: (id: string) => Promise<void>;

  @run.Action
  private updateLog!: (id: string) => Promise<void>;

  @run.Action
  private loadStats!: (id: string) => Promise<void>;

  @toast.Mutation
  private setToast!: (toast: IToast | null) => void;

  @project.Getter
  currentItem!: Project;

  followLog = false;
  firstRowVisible = false;
  lastRowVisible = false;

  @Watch('currentItem._id')
  async onCurrentItemChanged() {
    await this.$router.push({ name: 'runs', params: { projectId: this.currentItem._id } });
  }

  async created(): Promise<void> {
    this.state.loaded = false;
    await this.loadItem(this.$route.params.id);
    this.state.loaded = true;
    await Promise.all([this.loadLog(this.$route.params.id), this.loadStats(this.$route.params.id)]);
    if (this.$route.hash) {
      this.scrollToHash();
    }
    if (this.item.active) {
      this.pollInterval = setInterval(async () => {
        await this.updateItem(this.$route.params.id);
        const logLinesBeforeUpdate = this.log.length;
        await Promise.all([this.updateLog(this.$route.params.id), this.loadStats(this.$route.params.id)]);
        const hasNewLines = this.log.length > logLinesBeforeUpdate;
        if (this.followLog && hasNewLines) {
          this.scrollToEnd();
        }
        if (!this.item.active) {
          clearInterval(this.pollInterval);
          clearInterval(this.tickInterval);
          this.followLog = false;
        }
      }, 5000);

      this.tickInterval = setInterval(() => {
        this.now = new Date();
      }, 1000);
    }
  }

  beforeDestroy(): void {
    clearInterval(this.pollInterval);
    clearInterval(this.tickInterval);
  }

  async abort(run: Run): Promise<void> {
    const reallyAbort = await this.$bvModal.msgBoxConfirm(`Really abort run “${run.description}”?`, {
      okVariant: 'danger',
      okTitle: 'Abort',
      cancelVariant: 'outline-dark',
      cancelTitle: 'Cancel',
    });
    if (reallyAbort) {
      await api.cancelRun(run._id);
      await this.updateItem(run._id);
      this.setToast({ message: `Run “${run.description}” was canceled.` });
    }
  }

  async remove(run: Run): Promise<void> {
    const reallyDelete = await this.$bvModal.msgBoxConfirm(`Really delete “${run.description}”?`, {
      okVariant: 'danger',
      okTitle: 'Delete',
      cancelVariant: 'outline-dark',
      cancelTitle: 'Cancel',
    });
    if (reallyDelete) {
      await api.deleteRun(run._id);
      await this.$router.push({ name: 'runs', params: { projectId: this.currentItem._id } });
      this.setToast({ message: `Run “${run.description}” was deleted.` });
    }
  }

  async retry(run: Run): Promise<void> {
    let reallyRetry = true;
    const environmentUpdated = run.environment.updatedAt > run.createdAt;
    const scheduleUpdated = run.schedule.updatedAt > run.createdAt;
    if (environmentUpdated || scheduleUpdated) {
      let slot;
      if (environmentUpdated && scheduleUpdated) {
        slot = 'environment and schedule';
      } else if (environmentUpdated) {
        slot = 'environment';
      } else {
        slot = 'schedule';
      }
      reallyRetry = await this.$bvModal.msgBoxConfirm(
        `The run's ${slot} ${
          environmentUpdated && scheduleUpdated ? 'have' : 'has'
        } been updated since the run was created. Retrying the run will use the updated ${slot} and may produce different results. Really retry run “${
          run.description
        }”?`,
        {
          okVariant: 'dark',
          okTitle: 'Retry',
          cancelVariant: 'outline-danger',
          cancelTitle: 'Cancel',
        }
      );
    }
    if (reallyRetry) {
      const retriedRun = await api.retryRun(run._id);
      await this.$router.push({ name: 'runs', params: { projectId: this.currentItem._id } });
      let message = `Retried running “${run.description}”`;
      if (run.description !== retriedRun.description) {
        message += ` as “${retriedRun.description}”`;
      }
      message += '.';
      this.setToast({ message });
    }
  }

  onLineNumberClicked(index: number, event: MouseEvent): void {
    this.followLog = false;
    const hash = this.$route.hash;
    const line = index + 1;
    if (!event.shiftKey || !hash) {
      if (hash === `#L${line}`) {
        this.$router.push({ hash: '' });
      } else {
        this.$router.push({ hash: `#L${line}` });
      }
    } else {
      const [start, end] = hash.slice(2).split('-').map(Number);
      if (start === line && !end) {
        this.$router.push({ hash: '' });
      } else if (start === line && end) {
        this.$router.push({ hash: `#L${line}` });
      } else if (start < line) {
        this.$router.push({ hash: `#L${start}-${line}` });
      } else if (start > line) {
        this.$router.push({ hash: `#L${line}-${start}` });
      }
    }
  }

  toggleSearchMode(): void {
    this.inSearchMode = !this.inSearchMode;
    if (!this.inSearchMode) {
      this.q = '';
    } else {
      this.$nextTick(() => {
        (this.$refs['search-input'] as HTMLInputElement).focus();
      });
    }
  }

  copyLog(): void {
    const text = this.log.join('\n');
    navigator.clipboard.writeText(text).then(() => {
      this.setToast({ message: 'Run log was copied to clipboard.' });
    });
  }

  downloadLog(): void {
    downloadUrl(`/api/run/${this.item._id}/log/download`);
  }

  downloadArtefact(): void {
    if (this.item.artifact) {
      downloadUrl(`/api/${this.item.artifact.download}`);
    }
  }

  getLogRowClass(line: string, index: number): string[] | undefined {
    const keywords = [];
    // text color based on log level
    for (const keyword of ['error', 'warn', 'info', 'debug']) {
      if (line.toLowerCase().includes(keyword)) {
        keywords.push(keyword);
        break;
      }
    }
    // background color based on search
    if (this.q && line.toLowerCase().includes(this.q.toLowerCase())) {
      keywords.push('search');
    }
    // background color based on hash anchors
    const [start, end] = this.$route.hash.slice(2).split('-').map(Number);
    const matchesStart = start === index + 1;
    const withinRange = start && end && index + 1 >= start && index + 1 <= end;
    if (matchesStart || withinRange) {
      keywords.push('highlight');
    }

    return keywords;
  }

  follow(): void {
    this.followLog = !this.followLog;
    if (this.followLog) {
      this.scrollToEnd();
    }
  }

  scrollUp(): void {
    this.followLog = false;
    this.firstRowVisible = true;
    // hide tooltip programmatically, because the button is disabled
    // instantly after clicking it and the tooltip would stay visible
    this.$root.$emit('bv::hide::tooltip', 'scroll-up');
    this.scrollToStart();
  }

  scrollDown(): void {
    this.followLog = false;
    this.lastRowVisible = true;
    // hide tooltip programmatically, because the button is disabled
    // instantly after clicking it and the tooltip would stay visible
    this.$root.$emit('bv::hide::tooltip', 'scroll-down');
    this.scrollToEnd();
  }

  private scrollToStart(): void {
    this.$nextTick(() => {
      const row = document.querySelector('#L1');
      row?.scrollIntoView({ behavior: 'smooth' });
    });
  }

  private setFirstRowVisible(isVisible: boolean) {
    this.firstRowVisible = isVisible;
  }

  private setLastRowVisible(isVisible: boolean) {
    this.lastRowVisible = isVisible;
  }

  private scrollToHash(): void {
    if (this.$route.hash) {
      this.$nextTick(() => {
        const [start] = this.$route.hash.slice(2).split('-').map(Number);
        if (start) {
          const row = document.querySelector(`#L${start}`);
          row?.scrollIntoView({ behavior: 'smooth' });
        }
      });
    }
  }

  private scrollToEnd(): void {
    this.$nextTick(() => {
      const lastRow = document.querySelector('.console div:last-child');
      lastRow?.scrollIntoView({ behavior: 'smooth' });
    });
  }

  get durationText(): string {
    const parts: string[] = [];
    const nowWhenActive = this.item.active ? this.now.toISOString() : undefined;

    // Ran duration: startedAt -> finishedAt or now (when active)
    const finishedAtOrNow = this.item.finishedAt || nowWhenActive;
    if (this.item.startedAt && finishedAtOrNow) {
      const running = [];
      running.push(this.item.active ? 'running for' : 'ran for');
      const duration = toSeconds(finishedAtOrNow) - toSeconds(this.item.startedAt);
      running.push(formatDuration(duration));
      // add estimated running duration when active
      if (this.item.active && this.item.estimatedRunningDurationInSeconds) {
        running.push(`(approx. ${formatDurationShort(this.item.estimatedRunningDurationInSeconds)})`);
      }
      parts.push(running.join(' '));
    }

    // Queue duration: createdAt -> startedAt or finishedAt (when canceled before start) or now (when active)
    const exitedQueueAt = this.item.startedAt || this.item.finishedAt || nowWhenActive;
    if (exitedQueueAt) {
      const duration = toSeconds(exitedQueueAt) - toSeconds(this.item.createdAt);
      parts.push(`queued for ${formatDuration(duration)}`);
    }
    return parts.join(', ');
  }

  get duration(): number | undefined {
    return this.item.active && this.item.startedAt ? toSeconds(this.now) - toSeconds(this.item.startedAt) : undefined;
  }

  get showProgress() {
    return this.item.active && this.item.startedAt && this.item.estimatedRunningDurationInSeconds !== null;
  }

  get progressVariant(): string {
    return this.duration !== undefined &&
      this.item.estimatedRunningDurationInSeconds !== null &&
      this.duration > this.item.estimatedRunningDurationInSeconds
      ? 'danger'
      : '';
  }

  get showStats(): boolean {
    return this.item.active || this.stats?.stats.length > 0;
  }
}
