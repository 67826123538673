


















import { Component, Vue } from 'vue-property-decorator';
import LicenseBanner from '../components/LicenseBanner.vue';
import NavigationBar from '../components/NavigationBar.vue';
import Toast from '../components/Toast.vue';
import TopBar from '../components/TopBar.vue';

@Component<Wrapper>({
  components: {
    TopBar,
    NavigationBar,
    LicenseBanner,
    Toast,
  },
})
export default class Wrapper extends Vue {
  get mainClass(): string | undefined {
    // note: this cannot be passed via properties,
    // as we set it from a *child* component in the
    // routing configuration, and these do not
    // propagate “upwards”.
    // https://stackoverflow.com/a/56921729
    if (this.$route.meta?.fullWidth) {
      return 'full-width';
    } else if (this.$route.meta?.largeWidth) {
      return 'large-width';
    } else {
      return undefined;
    }
  }
}
