











































































































import { ability } from '@/auth/abilities';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowDownAZ, faFile, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { capitalize } from 'vue-string-filter';
import { namespace } from 'vuex-class';
import * as api from '../api';
import { File, FileList, Project, QueryFileType, SortFiles } from '../api';
import DateTime from '../components/DateTime.vue';
import EmptyListPlaceholder from '../components/EmptyListPlaceholder.vue';
import FileExtensionIcon from '../components/FileExtensionIcon.vue';
import FileTypeIcon from '../components/FileTypeIcon.vue';
import SearchInput from '../components/SearchInput.vue';
import SortInput from '../components/SortInput.vue';
import { IToast } from '../store/toast.module';
import { downloadUrl } from '../utils';

library.add(faFile, faSpinner, faArrowDownAZ);

const toast = namespace('toast');
const project = namespace('project');

@Component({
  components: { EmptyListPlaceholder, DateTime, FileTypeIcon, FileExtensionIcon, SearchInput, SortInput },
})
export default class FileListComponent extends Vue {
  private pollInterval: number | undefined;
  fileList: FileList | null = null;
  filter: QueryFileType = 'all';
  uploading = false;
  private q = '';
  sort: SortFiles = 'originalName';

  @Ref('file') readonly fileInput!: HTMLInputElement;

  @project.Getter
  currentItem!: Project;

  @toast.Mutation
  private setToast!: (toast: IToast | null) => void;

  @Watch('currentItem._id')
  onCurrentItemChanged() {
    this.load();
  }

  async mounted(): Promise<void> {
    await this.load();
    this.pollInterval = setInterval(() => void this.load(), 10000);
  }

  beforeDestroy(): void {
    clearInterval(this.pollInterval);
  }

  onFilter(filter: QueryFileType) {
    this.filter = filter;
    this.load();
  }

  onSortInput(sort: SortFiles) {
    this.sort = sort;
    this.load();
  }

  onSearchInput(q: string) {
    this.q = q;
    this.load();
  }

  private async load(): Promise<void> {
    if (this.currentItem) {
      this.fileList = await api.listFiles({
        $type: this.filter,
        q: this.q,
        project: this.currentItem._id,
        sort: this.sort,
      });
    }
  }

  async upload(event: Event) {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      this.uploading = true;
      try {
        await api.uploadFile({ file, project: this.currentItem._id });
        this.filter = 'all';
        this.load();
        this.setToast({ message: `File “${file.name}” was uploaded.` });
      } finally {
        this.uploading = false;
        this.fileInput.value = '';
      }
    }
  }

  async remove(file: File): Promise<void> {
    const reallyDelete = await this.$bvModal.msgBoxConfirm(`Really delete file “${file.originalName}”?`, {
      okVariant: 'danger',
      okTitle: 'Delete',
      cancelVariant: 'outline-dark',
      cancelTitle: 'Cancel',
    });
    if (reallyDelete) {
      await api.deleteFile(file._id);
      this.load();
      this.setToast({ message: `File “${file.originalName}” was deleted.` });
    }
  }

  download(file: File): void {
    downloadUrl(`/api/${file.download}`);
  }

  get cannotCreate(): boolean {
    return !ability.can('create', { kind: 'file', project: this.currentItem._id });
  }

  get filterOptions(): { key: QueryFileType; class: string; label: string; count: number }[] {
    const fileList = this.fileList;
    if (!fileList) {
      return [];
    }
    return (['all', 'upload', 'artifact'] as const).map((property) => ({
      key: property,
      class: this.filter === property ? 'active' : '',
      label: capitalize(property),
      count: fileList[`${property}Count` as keyof FileList] as number,
    }));
  }

  get sortOptions(): { key: SortFiles; label: string }[] {
    return [
      { key: 'originalName', label: 'Name' },
      { key: 'size', label: 'Size ↓' },
      { key: '-size', label: 'Size ↑' },
      { key: 'createdAt', label: 'Created ↓' },
      { key: '-createdAt', label: 'Created ↑' },
    ];
  }
}
