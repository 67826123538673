





















































































import FileExtensionIcon from '@/components/FileExtensionIcon.vue';
import FileTypeIcon from '@/components/FileTypeIcon.vue';
import { downloadUrlToString, transformWorkflowSVG } from '@/utils';
import { formatFileSize } from '@/utils';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faDownload, faMagnifyingGlassMinus, faMagnifyingGlassPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import DOMPurify from 'dompurify';
import svgPanZoom from 'svg-pan-zoom';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { File, Project } from '../api';
import { downloadUrl, formatDateTime } from '../utils';

const file = namespace('file');
const project = namespace('project');

library.add(faMagnifyingGlassPlus, faMagnifyingGlassMinus, faDownload);

@Component({
  components: { FileTypeIcon, FileExtensionIcon, FontAwesomeIcon },
})
export default class FileEdit extends Vue {
  /** Only show the UI once the data has loaded;
   * needed to wrap this into an object, by just
   * using a plain flag it wouldn’t work -- binding
   * issue? */
  state: { loaded: boolean } = { loaded: false };
  private panZoomInstance: any | null = null;
  private minZoom = 1;
  private maxZoom = 10;
  private zoomInEnabled = false;
  private zoomOutEnabled = false;
  private resetEnabled = false;
  private initialPan: { x: number; y: number } | null = null;
  private previewContent: string | null = null;

  @file.Getter
  item!: File;

  @file.Action
  loadItem!: (id: string) => Promise<void>;

  @project.Getter('currentItem')
  project!: Project;

  @Watch('project._id')
  async onProjectChanged() {
    await this.$router.push({ name: 'files', params: { projectId: this.project._id } });
  }

  @Ref('preview')
  readonly preview!: HTMLDivElement;

  async created() {
    this.state.loaded = false;
    await this.loadItem(this.$route.params.id);
    await this.loadPreview();
    this.state.loaded = true;
    this.$nextTick(() => this.initializePanZoom());
  }

  async loadPreview() {
    if (this.item.preview) {
      const rawSvg = await downloadUrlToString(`/api/${this.item.preview}`);
      const transformedSvg = transformWorkflowSVG(rawSvg);
      if (transformedSvg) {
        this.previewContent = DOMPurify.sanitize(transformedSvg, { USE_PROFILES: { svg: true } });
      }
    }
  }

  initializePanZoom() {
    const svgElement = this.preview?.querySelector('svg');
    if (!svgElement) {
      return;
    }

    const svgWidth = svgElement.width.baseVal.value || svgElement.clientWidth;
    const svgHeight = svgElement.height.baseVal.value || svgElement.clientHeight;
    const container = this.preview.getBoundingClientRect();

    if (svgWidth && svgHeight && container.width && container.height) {
      const scaleX = svgWidth / container.width;
      const scaleY = svgHeight / container.height;
      this.minZoom = Math.max(0.5, Math.min(scaleX, scaleY, 1));
      this.maxZoom = Math.max(scaleX, scaleY, 1);
    }

    this.panZoomInstance = svgPanZoom(svgElement, {
      minZoom: this.minZoom,
      maxZoom: this.maxZoom,
      contain: false,
      fit: false,
      center: false,
      onUpdatedCTM: () => this.updateZoomEnabled(),
    });

    this.panZoomInstance.zoom(this.minZoom);
    this.panZoomInstance.center();

    this.initialPan = this.panZoomInstance.getPan();
    this.updateZoomEnabled();
  }

  private updateZoomEnabled(): void {
    const epsilon = 0.001;
    const pan = this.panZoomInstance.getPan();
    const zoom = this.panZoomInstance.getZoom();
    this.zoomInEnabled = this.maxZoom != null && Math.abs(zoom - this.maxZoom) > epsilon;
    this.zoomOutEnabled = this.minZoom != null && Math.abs(zoom - this.minZoom) > epsilon;
    // reset button
    if (this.initialPan != null) {
      const centered = Math.abs(pan.x - this.initialPan.x) < epsilon && Math.abs(pan.y - this.initialPan.y) < epsilon;
      this.resetEnabled = this.zoomOutEnabled || !centered;
    }
  }

  beforeDestroy() {
    this.panZoomInstance?.destroy();
  }

  get fileOrigin(): string {
    return this.item.type.charAt(0).toUpperCase() + this.item.type.slice(1);
  }

  get fileSize(): string {
    return formatFileSize(this.item.size);
  }

  zoomIn() {
    this.panZoomInstance.zoomIn();
  }

  zoomOut() {
    this.panZoomInstance.zoomOut();
  }

  resetZoom() {
    this.panZoomInstance.zoom(this.minZoom);
    this.panZoomInstance.center();
  }

  get createdAt(): string | undefined {
    return formatDateTime(this.item?.createdAt)?.combined;
  }

  download(file: File): void {
    downloadUrl(`/api/${file.download}`);
  }
}
