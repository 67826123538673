








































import { Component, Prop, Vue } from 'vue-property-decorator';

interface IVersionResponse {
  items: IVersionResult[];
}

interface IVersionResult {
  version: string;
  releaseDate: string;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line no-undef
const nodepitApiBaseUrl = NODEPIT_API_BASE;

@Component
export default class VersionInput extends Vue {
  @Prop() readonly value!: string;

  private versions: IVersionResult[] = [];

  async created() {
    const result = await fetch(`${nodepitApiBaseUrl}/versions?arch=x86_64&os=linux`);
    if (result.ok) {
      const json: IVersionResponse = await result.json();
      this.versions =
        json.items
          .filter((v) => {
            const [major, ,] = v.version.split('.').map(Number);
            // XXX keep in sync with our FAQ.md
            return major >= 3;
          })
          .reverse() || [];
      this.versions.push({
        version: 'nightly',
        releaseDate: new Date().toISOString().slice(0, 10),
      });
    }
  }

  readonly autoCompleteStyle = {
    defaultInput: 'form-control',
    suggestions: 'list-group',
    suggestItem: 'list-group-item list-group-item-action',
  };

  filteredVersions(value: string): IVersionResult[] {
    return this.versions.filter((v) => v.version.startsWith(value));
  }

  onVersionChanged(value: string): void {
    this.$emit('input', value);
  }
}
